import React, { useMemo } from 'react';

import DefaultRoute, { DefaultRouteProps } from './DefaultRoute';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

export enum RouteRole {
  private = 'private',
  public = 'public',
  none = 'none',
}

export type RouteRoleType = keyof typeof RouteRole;

export interface RouteProps extends DefaultRouteProps {
  routeRole?: RouteRole | RouteRoleType;
}

const Route: React.FC<RouteProps> = (props) => {
  const { routeRole = RouteRole.none, ...other } = props;

  const RouteComponent = useMemo(
    () =>
      ({
        [RouteRole.private]: PrivateRoute,
        [RouteRole.public]: PublicRoute,
        [RouteRole.none]: DefaultRoute,
      })[routeRole],
    [routeRole],
  );

  return <RouteComponent {...other} />;
};

export default React.memo(Route);
