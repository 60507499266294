import React from 'react';
import { Theme } from '@mui/material';

import TableCellUi, { TableCellProps } from 'components/@ui/TableCell';
import { clsx, makeSxStyles, parseSx } from '@helpers';

import { cellsSize } from './@config';

export const sxes = makeSxStyles({
  cellStyles: {
    p: 0,
    textAlign: 'center',
    borderBottom: 'none',
    position: 'relative',
    border: 1,
    borderColor: 'transparent',
    maxWidth: cellsSize.heatMap.width,
    transition: (theme: Theme) => theme.transitions.create('all'),
    '&::before': {
      transition: (theme: Theme) => theme.transitions.create('all'),
      opacity: 0,
      content: '""',
      height: 'calc(100% - 24px)',
      position: 'absolute',
      top: 0,
      width: cellsSize.heatMap.width,
      ml: '-30px',
    },
  },
  cellStylesHover: {
    borderColor: 'blue.main',
    cursor: 'default',
    '&::before ': { opacity: 1 },
  },
})();

const TableCell: React.FC<TableCellProps> = ({ sx, ...other }) => {
  return (
    <TableCellUi
      width={cellsSize.heatMap.width}
      height="100%"
      sx={clsx(sxes.cellStyles, { '&:hover ': sxes.cellStylesHover }, ...parseSx(sx))}
      {...other}
    />
  );
};

export default React.memo(TableCell);
