import { useCallback, useMemo } from 'react';
import debouncePromise from 'awesome-debounce-promise';
import { AnyAction, Dispatch } from 'redux';
import { useSelector, Selector } from 'react-redux';
import { AxiosResponse } from 'axios';

import { RootState, useAppDispatch } from '@redux/store';

type UseAPIOption = { debounce?: number };
type ActionCallback<T> = (dispatch: Dispatch<AnyAction>) => Promise<AxiosResponse<T> | undefined>;
export type UseAPI<S, T, P extends unknown[]> = [S, (...payload: P) => Promise<AxiosResponse<T>>];

export default <S, T, P extends unknown[] = unknown[]>(
  action: (...arg: P) => ActionCallback<T>,
  selector: Selector<RootState, S> | undefined = undefined,
  options: UseAPIOption = {},
) => {
  const { debounce } = options;
  const dispatch = useAppDispatch();
  const selectorWrapper = selector || ((state: RootState) => state.plug);
  const data = useSelector<RootState, ReturnType<typeof selectorWrapper>>(selectorWrapper);
  const runRequest = useCallback((...arg: P) => dispatch(action(...arg)), [action, dispatch]);
  const request = debounce ? debouncePromise(runRequest, debounce) : runRequest;

  return useMemo(() => [data, request], [data, request]) as UseAPI<S, T, P>;
};
