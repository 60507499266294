import { TimeLineBar } from '../@types';

export const updateStatus = <T extends TimeLineBar.Statuses>(
  todos: TimeLineBar.ToDo[],
  todoId: number,
  status: T,
) => {
  return todos.map((todo) => ({
    ...todo,
    ...(todo.id === todoId && { status }),
  }));
};

export default <T extends TimeLineBar.Statuses>(
  phases: TimeLineBar.Phase[],
  phaseId: number,
  todoId: number,
  status: T,
) => {
  return phases.map((phase) => ({
    ...phase,
    ...(phase.id === phaseId && {
      todos: updateStatus(phase.todos, todoId, status),
    }),
  }));
};
