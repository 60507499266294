import { lazy } from 'react';

import RootRoutes from 'components/@common/TermsAndPrivacy';
import { lazyRetry } from 'utils/lazyRetry';

import { RouteRole } from './Route';
import { ROUTES } from './config';

const AdminRedirection = lazy(() => lazyRetry(() => import(/* webpackChunkName: "redirection" */ 'components/@main/AdminRedirection'), 'redirection')); /* prettier-ignore */
const LandingPage = lazy(() => lazyRetry(() => import(/* webpackChunkName: "landingPage" */ 'pages/Landing'), 'landingPage')); /* prettier-ignore */
const EmbedSignUp = lazy(() =>lazyRetry(()=> import(/* webpackChunkName: "embedSignUp" */ 'pages/EmbeddedSignUp'), 'embedSignUp')); /* prettier-ignore */
const AuthPages = lazy(() => lazyRetry(() => import(/* webpackChunkName: "authPages" */ 'pages/Auth'), 'authPages')); /* prettier-ignore */
const Settings = lazy(() => lazyRetry(() => import(/* webpackChunkName: "settings" */ 'pages/Settings'), 'settings')); /* prettier-ignore */
const Advisor = lazy(() => lazyRetry(() => import(/* webpackChunkName: "advisor" */ 'pages/Advisor'), 'advisor')); /* prettier-ignore */
const Vendors = lazy(() => lazyRetry(() => import(/* webpackChunkName: "vendors" */ 'pages/Vendors'), 'vendors')); /* prettier-ignore */
const Vendor = lazy(() => lazyRetry(() => import(/* webpackChunkName: "vendor" */ 'pages/Vendor'), 'vendor')); /* prettier-ignore */
const Scorecard = lazy(() => lazyRetry(() => import(/* webpackChunkName: "scorecard" */ 'pages/Scorecard'), 'scorecard')); /* prettier-ignore */
const Scores = lazy(() => lazyRetry(() => import(/* webpackChunkName: "scores" */ 'pages/Summary'), 'scores')); /* prettier-ignore */
const Workspace = lazy(() => lazyRetry(() => import(/* webpackChunkName: "workspace" */ 'pages/Workspace'), 'workspace')); /* prettier-ignore */
const TeamPage = lazy(() => lazyRetry(() => import(/* webpackChunkName: "team" */ 'pages/Team'), 'team')); /* prettier-ignore */
const Invitations = lazy(() => lazyRetry(() => import(/* webpackChunkName: "invitations" */ 'pages/Invitations'), 'invitations')); /* prettier-ignore */
const ProcessMapping = lazy(() => lazyRetry(() => import(/* webpackChunkName: "processMapping" */ 'pages/ProcessMapping'), 'processMapping')); /* prettier-ignore */
const AddNewProject = lazy(() => lazyRetry(() => import(/* webpackChunkName: "addNewProject" */ 'pages/AddNewProject'), 'addNewProject')); /* prettier-ignore */
const ComingSoonState = lazy(() => lazyRetry(() => import(/* webpackChunkName: "comingSoonState" */ 'components/@common/ComingSoonState'), 'comingSoonState')); /* prettier-ignore */

export const routes = [
  { path: ROUTES.termsOfUse, exact: true, component: RootRoutes.TermsOfUse },
  { path: ROUTES.privacyNotice, exact: true, component: RootRoutes.PrivacyNotice },
  { path: ROUTES.admin.root, component: AdminRedirection },
  { path: ROUTES.root, routeRole: RouteRole.public, exact: true, component: LandingPage },
  { path: ROUTES.embedSignUp.root, exact: true, component: EmbedSignUp },
  { path: ROUTES.auth.root, component: AuthPages },
  { path: ROUTES.settings.root, routeRole: RouteRole.private, component: Settings },
  { path: ROUTES.advisor, routeRole: RouteRole.private, exact: true, component: Advisor },
  { path: ROUTES.vendors.root, routeRole: RouteRole.private, component: Vendors },
  { path: ROUTES.vendorPage, routeRole: RouteRole.private, exact: true, component: Vendor },
  { path: ROUTES.scorecard.root, routeRole: RouteRole.private, component: Scorecard },
  { path: ROUTES.scores, routeRole: RouteRole.private, exact: true, component: Scores },
  { path: ROUTES.workspace, routeRole: RouteRole.private, exact: true, component: Workspace },
  { path: ROUTES.team, routeRole: RouteRole.private, exact: true, component: TeamPage },
  { path: ROUTES.invitations.root, component: Invitations },
  { path: ROUTES.processMapping.root, routeRole: RouteRole.private, component: ProcessMapping },
  { path: ROUTES.addNewProject.root, routeRole: RouteRole.private, component: AddNewProject },
  { path: ROUTES.todo, routeRole: RouteRole.private, exact: true, component: ComingSoonState },
  { path: ROUTES.documents, routeRole: RouteRole.private, exact: true, component: ComingSoonState },
  { path: ROUTES.resources, routeRole: RouteRole.private, exact: true, component: ComingSoonState },
];
