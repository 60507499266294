import { useCallback } from 'react';

import { useAPI } from '@hooks';

import { sendNewEmail } from '../@slices';

const useFetchSendNewEmail = () => {
  return useAPI(useCallback((email: string) => sendNewEmail.action(email), []));
};

export default useFetchSendNewEmail;
