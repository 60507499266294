import React, { useMemo } from 'react';

import When from 'components/@main/When';
import { useIsTablet } from '@hooks';

import HideBlockWrapper from './HideBlockWrapper';
import HideIconWrapper from './HideIconWrapper';

const DownloadPDFHideButtonWrapper: React.FC = () => {
  const isTablet = useIsTablet();

  return useMemo(
    () => (
      <When
        condition={isTablet}
        disabledWrapper
        fallback={
          <HideBlockWrapper
            position="absolute"
            bottom={0}
            left={0}
            height={1}
            sx={{ backdropFilter: 'blur(4px)' }}
            isShowText={false}
          />
        }
      >
        <HideIconWrapper position="absolute" bottom={0} left={0} height={1} />
      </When>
    ),
    [isTablet],
  );
};

export default DownloadPDFHideButtonWrapper;
