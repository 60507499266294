import baseAPI, { APIResponse } from '@api';
import { User } from '@types';

const apiCredentials = baseAPI(true);

//--------------------------------------------------------------------------------------------------
// Get self data, validate token
//--------------------------------------------------------------------------------------------------
export function fetchSelfData(): APIResponse<User.User> {
  return apiCredentials.get('/client_auth/validate_token');
}
//--------------------------------------------------------------------------------------------------
