import React from 'react';
import { useToggle } from 'react-use';

import Box from 'components/@ui/Box';
import Button from 'components/@ui/Button';
import Icon from 'components/@ui/Icon';
import Typography from 'components/@ui/Typography';
import { FormRender } from 'components/@main/Form';

import { Dialog400 } from './@components';

const DialogShortLict: React.FC = () => {
  const [open, toogleOpen] = useToggle(true);

  return (
    <Dialog400 open={open}>
      <FormRender render={Box} gap={3}>
        <Typography semiBold align="center" mt={2} display="flex" justifyContent="center" gap={0.5}>
          <Icon name="heart-shortlisted-filled-path" /> Vendors are waiting for your hearts!
        </Typography>
        <Typography align="center">
          Add up to 8 vendors to your shortlist to compare in a more focused manner with your team
          and an OutSail advisor
        </Typography>
        <Typography align="center" color="gray.main">
          Go to Vendors page –
          <Typography component="span" position="relative" top={1.5}>
            {'>'}
          </Typography>{' '}
          Press
          <Icon mx={0.5} name="heart-shortlisted-empty-path" sx={{ verticalAlign: 'middle' }} />
          button
        </Typography>
        <Button fullWidth onClick={toogleOpen}>
          OK
        </Button>
      </FormRender>
    </Dialog400>
  );
};

export default DialogShortLict;
