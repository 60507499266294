import snakecaseKeys from 'snakecase-keys';

import baseAPI, { APIResponse } from '@api';
import { User } from '@types';

import { makeUserFetchArguments } from './@helpers';

const api = baseAPI(false);
const apiCredentials = baseAPI();

//--------------------------------------------------------------------------------------------------
// Create Client
//--------------------------------------------------------------------------------------------------
export type FetchCreateUserBody = {
  email: string;
  name: string;
  receiveEmails?: boolean;
  avatar?: File | null;
  password: string;
  projectId?: number;
  role?: User.Roles;
  createProjectWithVisibleData?: boolean;
  verified?: boolean;
};
export function fetchCreateUser(props: FetchCreateUserBody): APIResponse<User.User> {
  return api.post('/client_auth', ...makeUserFetchArguments(props, 'client'));
}
//--------------------------------------------------------------------------------------------------
// Update Client
//--------------------------------------------------------------------------------------------------
type UpdateUserBodyFields = 'email' | 'name' | 'receiveEmails' | 'avatar';
export type FetchUpdateUserBody = Partial<Pick<FetchCreateUserBody, UpdateUserBodyFields>>;
export function fetchUpdateUser(props: FetchUpdateUserBody, id: number): APIResponse<User.User> {
  return apiCredentials.put(`/clients/${id}`, ...makeUserFetchArguments(props, 'client'));
}
//--------------------------------------------------------------------------------------------------
// Send New Email
//--------------------------------------------------------------------------------------------------
export function fetchSendNewEmail(email: string) {
  return apiCredentials.post(`/clients/send_verification_on_new_email`, { client: { email } });
}
//--------------------------------------------------------------------------------------------------
// Update Email
//--------------------------------------------------------------------------------------------------
export function fetchUpdateEmail(email: string, code: string): APIResponse<User.User> {
  return apiCredentials.put(`/clients/update_email`, {
    verification_code: code,
    client: { email },
  });
}
//--------------------------------------------------------------------------------------------------
// Update Password
//--------------------------------------------------------------------------------------------------
export type FetchUpdatePasswordBody = { currentPassword: string; password: string };
export function fetchUpdatePassword(props: FetchUpdatePasswordBody) {
  return apiCredentials.put(`/clients/update_password`, { client: snakecaseKeys(props) });
}
//--------------------------------------------------------------------------------------------------
