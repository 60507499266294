import { IdField, MainFields } from './types';

enum ROLES_ENUM {
  admin = 'admin',
  client = 'client',
  owner = 'owner',
}

enum PROVIDERS_ENUM {
  email = 'email',
  google = 'google',
  microsoft = 'microsoft',
  linkedin = 'linkedin',
}

const ROLES_NAME_ENUM = {
  [ROLES_ENUM.admin]: 'admin',
  [ROLES_ENUM.client]: 'user',
  [ROLES_ENUM.owner]: 'owner',
} as const;

export namespace User {
  export type UserFields = ProjectsField &
    MainFields<
      Avatar & {
        email: string;
        provider: Providers;
        verified: boolean;
        receiveEmails: boolean;
      }
    >;

  export type Avatar = {
    avatar: string | null;
  };

  export const ROLES = ROLES_ENUM;
  export type Roles = keyof typeof ROLES_ENUM;

  export const PROVIDERS = PROVIDERS_ENUM;
  export type Providers = keyof typeof PROVIDERS_ENUM;

  export const USER_ROLES_NAME = ROLES_NAME_ENUM;

  export type Projects = {
    projectId: number;
    projectName: number;
    ownerName?: string;
    ownerAvatar?: string | null;
    clientRole: Roles;
  };

  export type ProjectsField = {
    projects: Projects[] | [];
  };

  export type UserForStorage = IdField<ProjectsField>;

  export type User = UserFields;
}
