import { useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { always, tryCatch } from 'ramda';

import { useProject } from '@hooks';
import { checkAllEquals, getExpiresDays } from '@helpers';

export type RemindersCaseType = 'shortlist' | 'workspace';
export type ReminderHiddenData = 'hiddenData';

const useReminders = () => {
  const project = useProject();

  const names = useMemo(
    () => ({
      shortlist: `remindShortlist-${project?.id}`,
      workspace: `remindWorkspace-${project?.id}`,
      hiddenData: `remindHiddenData-${project?.id}`,
    }),
    [project?.id],
  );

  const [cookie, setCookie] = useCookies([names.shortlist, names.workspace, names.hiddenData]);

  const getCookie = useCallback(
    (key: string) => tryCatch(() => cookie[key] as true | undefined, always(undefined))(),
    [cookie],
  );

  const onSetCookie = useCallback(
    (reminders: RemindersCaseType | ReminderHiddenData, expire: number = 3) =>
      () =>
        setCookie(names[reminders], true, { expires: getExpiresDays(expire), sameSite: 'strict' }),
    [names, setCookie],
  );

  const lessItemsThan = useCallback(
    (count: number, reminders: RemindersCaseType) =>
      ({
        shortlist: project?.shortlistedProducts?.length < count,
        workspace: project?.workspacedProducts?.length < count,
      })[reminders],
    [project?.shortlistedProducts?.length, project?.workspacedProducts?.length],
  );

  const createdAtCheck = useCallback(
    (days: number) => {
      const createdAt = project?.createdAt ? new Date(project?.createdAt) : new Date();
      return getExpiresDays(-days) > createdAt;
    },
    [project?.createdAt],
  );

  return useMemo(
    () => ({
      remindShortlist: checkAllEquals(
        !project?.chosenProduct,
        lessItemsThan(3, 'shortlist'),
        createdAtCheck(3),
        !getCookie(names.shortlist),
      ),
      remindWorkspace: checkAllEquals(
        !project?.chosenProduct,
        lessItemsThan(2, 'workspace'),
        createdAtCheck(3),
        !getCookie(names.workspace),
      ),
      remindHiddenData: checkAllEquals(
        project?.hiddenInformation,
        !project?.chosenProduct,
        createdAtCheck(3),
        !getCookie(names.hiddenData),
      ),
      setCookie: onSetCookie,
    }),
    [
      createdAtCheck,
      getCookie,
      lessItemsThan,
      names.hiddenData,
      names.shortlist,
      names.workspace,
      onSetCookie,
      project?.chosenProduct,
      project?.hiddenInformation,
    ],
  );
};

export default useReminders;
