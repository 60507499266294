import React from 'react';

import Box from 'components/@ui/Box';
import When from 'components/@main/When';
import Navbar from 'components/@main/Navbar';
import TimeLineBar, { TimeLineBarPlug } from 'components/@main/TimeLineBar';
import { Children } from '@types';
import { useIsTablet } from '@hooks';

import { useAppLayout } from './@hooks';

export enum AppLayoutSizes {
  timeLineBarHeight = 60,
  mobileTimeLineHeight = 80,
  emptyValue = 0,
}

const AppLayout: React.FC<Children> = ({ children }) => {
  const { hasTimeLineBar, hasTimeLineBarPlug, hasNavBar } = useAppLayout();
  const hasTimeLine = hasTimeLineBar || hasTimeLineBarPlug;
  const isTablet = useIsTablet();
  const offset = Number(
    hasTimeLine && (isTablet ? AppLayoutSizes.emptyValue : AppLayoutSizes.timeLineBarHeight),
  );

  return (
    <Box display="flex" flexDirection="column" bgcolor="background.default">
      <When disabledWrapper condition={hasTimeLineBar}>
        <TimeLineBar />
      </When>
      <When disabledWrapper condition={hasTimeLineBarPlug}>
        <TimeLineBarPlug />
      </When>
      <Box
        display="flex"
        maxHeight={`calc(100vh - ${offset}px)`}
        height={`calc(100vh - ${offset}px)`}
        component="section"
      >
        <When disabledWrapper condition={hasNavBar}>
          <Navbar />
        </When>
        <Box
          component="main"
          width="100%"
          sx={{ overflowX: 'auto', '&': (theme) => theme.scrollbar(isTablet ? 0 : 8) }}
          position="relative"
        >
          <Box {...(isTablet && hasTimeLine && { position: 'fixed', width: 1 })}>
            <Box
              {...(isTablet &&
                hasTimeLine && {
                  position: 'relative',
                  width: 1,
                  top: AppLayoutSizes.mobileTimeLineHeight,
                  maxHeight: `calc(100vh - ${AppLayoutSizes.mobileTimeLineHeight}px)`,
                  height: '100vh',
                  sx: { '&': (theme) => theme.scrollbar(0) },
                  overflow: 'auto',
                  pb: 10,
                })}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AppLayout;
